<div class="privacy">
  <app-header></app-header>
  <div class="container">
    <div class="term-of-service">
      <h2 class="title">Terms of Service</h2>
      <h2 class="subtitle">Acceptance of terms</h2>
      <p>
        Thank you for using ZTLoyalty. These Terms of Service (the "Terms") are
        intended to make you aware of your legal rights and responsibilities
        with respect to your access to and use of the ZTLoyalty website at
        www.ztloyalty.in (the "Site") and any related mobile or software
        applications ("ZTLoyalty Platform") including but not limited to
        delivery of information via the website whether existing now or in the
        future that link to the Terms (collectively, the "Services").
      </p>
      <h2 class="title">Definitions</h2>
      <h2 class="subtitle">Customer</h2>
      <p>
        "Customer" or "You" or "Your" refers to you, as a customer of the
        Services. A customer is someone who accesses or uses the Services for
        the purpose of sharing, displaying, hosting, publishing, transacting, or
        uploading information or views or pictures and includes other persons
        jointly participating in using the Services including without limitation
        a user having access to 'restaurant business page' to manage claimed
        business listings or otherwise.
      </p>
      <h2 class="subtitle">Content</h2>
      <p>
        "Content" will include (but is not limited to) reviews, images, photos,
        location data, nearby places, and all other forms of information or
        data. "Your content" or "Customer Content" means content that you
        upload, share or transmit to, through or in connection with the
        Services, such as likes, ratings, reviews, images, photos, messages,
        chat communication, profile information, or any other materials that you
        publicly display or displayed in your account profile. "ZTLoyalty
        Content" means content that ZTLoyalty creates and make available in
        connection with the Services including, but not limited to, visual
        interfaces, interactive features, graphics, design, compilation,
        computer code, products, software, aggregate ratings, reports and other
        usage-related data in connection with activities associated with your
        account and all other elements and components of the Services excluding
        Your Content and Third Party Content.
      </p>
      <h2 class="title">Eligibility to use the services</h2>
      <ul>
        <li>
          You hereby represent and warrant that you are at least eighteen (18)
          years of age or above and are fully able and competent to understand
          and agree the terms, conditions, obligations, affirmations,
          representations, and warranties set forth in these Terms.
        </li>
        <li>
          Compliance with Laws. You are in compliance with all laws and
          regulations in the country in which you live when you access and use
          the Services. You agree to use the Services only in compliance with
          these Terms and applicable law, and in a manner that does not violate
          our legal rights or those of any third party(ies).
        </li>
      </ul>
      <h2 class="title">Use of services by you or Customer</h2>
      <ul>
        <li>
          You must create an account in order to use some of the features
          offered by the Services, including without limitation to 'claim your
          business listing' on the Services. Use of any personal information you
          provide to us during the account creation process is governed by our
          Privacy Policy. You must keep your password confidential and you are
          solely responsible for maintaining the confidentiality and security of
          your account, all changes and updates submitted through your account,
          and all activities that occur in connection with your account.
        </li>
        <li>
          In creating an account, you represent to us that all information
          provided to us in such a process is true, accurate and correct, and
          that you will update your information as and when necessary in order
          to keep it accurate. If you are creating an account, then you
          represent to us that you are the owner. You may not impersonate
          someone else, create or use an account for anyone other than yourself,
          provide an email address other than your own, create multiple
          accounts, or provide or use false information to obtain access to a
          business' listing on the Services that you are not legally entitled to
          claim. You acknowledge that any false claiming of a business listing
          may cause ZTLoyalty or third parties to incur substantial economic
          damages and losses for which you may be held liable and accountable.
        </li>
        <li>
          You agree not to access (or attempt to access) any of the Services by
          any means other than the interface that is provided by ZTLoyalty,
          unless you have been specifically allowed to do so, by way of a
          separate agreement with ZTLoyalty. You specifically agree not to
          access (or attempt to access) any of the Services through any
          automated means (including use of scripts or web crawlers) and shall
          ensure that you comply with the instructions set out in any robots.txt
          file present on the Services.
        </li>
        <li>
          You agree that you will not engage in any activity that interferes
          with or disrupts the Services (or the servers and networks which are
          connected to the Services). You shall not delete or revise any
          material or information posted by any other Customer(s), shall not
          engage in spamming, including but not limited to any form of emailing,
          posting or messaging that is unsolicited.
        </li>
      </ul>
      <h2 class="title">ZTLoyalty Memberships:</h2>
      <p>
        ZT Loyalty offers Free guest users and currently four tiers of premium
        paid memberships. These memberships categories are subjected to change
        with prior information to user
      </p>
      <p>
        Free users - ZT GUEST; will have access to basic app features such as
      </p>
      <ul>
        <li>Room Bookings at Best available Rate</li>
        <li>Food delivery at menu price + delivery charge</li>
        <li>Loyalty points on select outlets</li>
        <li>View menu, photos and other content and information of outlets</li>
        <li>Phone number, address and map locations</li>
        <li>
          Feedback system prompts on outlet visit for enhanced guest experience
          Above features are subject to changes for a smoother app and guest
          experience
        </li>
      </ul>
      <p>Paid Memberships. There are four membership categories.</p>
      <ul>
        <li>ZT Club</li>
        <li>ZT Premium</li>
        <LI>ZT Vip</LI>
        <LI>ZT Elite</LI>
      </ul>
      <P
        >Above memberships have access to all the basic features and access to
        events listings and booking and a lineup of additional benefits as
        listed on the membership screen in the app and website, these features
        are subject to changes for a smoother app and guest experience
      </P>
      <P
        >Paid Members will be issued as a physical membership card, which will
        also act as a cash card in select outlets, only for events. Access to
        events feature is exclusive to paid members only and inapplicable to
        Guest user (free memberships) Guests are responsible for their own
        cards, and management is not responsible for misplacement or any damage
        to magnetic strip and its data and balance In case of a card is lost, a
        new card will be issued for guests (with active paid memberships).
        Guests can connect with us on WhatsApp helpline from app or website our
        listed email id for any assistance. We request users to report
        immediately or within 24hours, in case of lost card; for us to
        deactivate and issue new card Paid memberships have options of auto
        renewal and one time purchase, in case of auto renewal the payment
        gateway will auto debit amount from preferred mode of payment. In case
        of one-time purchase, the membership will be active for one month from
        date of purchase. Paid Memberships are non-Refundable and will stay
        active for a period of one month from date of purchase. If guest wishes
        to cancel the subscription it will be canceled from the end of their
        tenure they have paid for. Any preloaded balance on membership cash card
        must be utilized by user before the expiry of a preloaded balance. The
        duration of validity of balance will differ with level of membership
        purchased. Management is not responsible for any misrepresentation The
        card and its benefits are non-transferable. The card must be used solely
        by the user for adding or redemption of loyalty points and as cash card.
        Any cases of stolen identity and misrepresentation by person or persons
        or group is punishable by law Your Membership card and welcome kit will
        be delivered to your residence as listed in profile. In case of address
        not local to Manipal, Udupi District, Karnataka. it will be couriered to
        you and the tracking ID will be provided. If Guests need any personal
        assistance, please connect to us on our listed WhatsApp number or email
        id and will be guided depending on scenario of assistance required
      </P>
      <h2 class="title">Customer Reviews</h2>
      <P
        >Customer reviews or ratings for Restaurants do not reflect the opinion
        of ZTLoyalty. ZTLoyalty receives multiple reviews or ratings for
        Restaurants by Customers, which reflect the opinions of the Customers.
        It is pertinent to state that each and every review posted on ZTLoyalty
        is the personal opinion of the Customer/reviewer only. ZTLoyalty is a
        neutral platform, which solely provides a means of communication between
        Customers/reviewers including Customers or restaurant
        owners/representatives with access to restaurant business page.
      </P>
      <h2 class="title">Room Reservation Cancellation &amp; Refund Policy</h2>
        <ul>
          <li>Cancellations made two or more days before check in date will be free</li>
          <li>In case of full refund on cancellation, there will be deduction of 5% as payment
            processing charges</li>
          <li>No-Shows &amp; cancellations made zero to one day before check in date will incur 100%
              charge of the booking.</li>
          <li>In case of refund, payment will be processed within three working days.</li>
        </ul>

        <h2 class="title">Online food order (home delivery) cancellation policy</h2>
        <ul>
          <li>No cancellation is allowed once the food &amp; beverage order is placed.</li>
          <li>Refund will not be initiated once the food order is placed on ZT Loyalty App/website.</li>
        </ul>

        <h2 class="title">Event booking cancellation policy</h2>
        <ul>
          <li>No cancellation is allowed, once the event ticket, cover charges booked through the</li>
          <li>website or App.</li>
          <li>Refund will not be initiated once the event ticket, cover charges booked and paid</li>
          <li>through the website or App.</li>
        </ul>

        <h2 class="title">Shipping Policy: Online food orders</h2>
        <ul>
          <li>Order will be processed for preparation immediately after receiving from the app/website.</li>
          <li>Order status can be tracked on website (Steps-order received/order processing/out for delivery /delivered)</li>
          <li>Order will be delivered to the customer in about 45 minutes after receiving the order</li>
          <li>For any queries related to the track of online order, customers can contact us on the contact details provided on
            the website/App.</li>
        </ul>

        <h2 class="title">Return Policy: Online food orders</h2>
        <ul>
          <li>In case of any wrong order delivered, we assure to provide the replacement
            with the product ordered or fully refund the amount of wrongly delivered
            item.</li>
        </ul>


        <h2 class="title">Club Rules</h2>
        <ul>
          <li>Entry limited to individuals over 18 years old.</li>
          <li>Bring your ID cards for age verification.</li>
          <li>⁠Men must wear closed footwear (slides & mules are permitted only if closed toe) and full length bottoms </li>
          <li>⁠Tickets once booked cannot be exchanged or refunded. Please refer our cancellation policy for more details </li>
          <li>No Outside Food or Drinks would be allowed in the premises of the venue </li>
          <li>Smoking (including e-cigarettes, vapes, cigars and similar appertus) is to be restricted to designated areas, the bar floor and table zones smoke-free zones. </li>
          <li>Loose cigarettes/ opened cigarette packs will not be permitted in the premises. Guests are requested to carry unopened sealed packs only </li>
          <li>⁠Bag checks or pat-downs may be conducted at the entrance for safety reasons</li>
          <li>Carrying any forms of prohibited Narcotics, with the intention of usage, or sale is strictly prohibited. If found, the management will be required to inform local law enforcement of the incident. </li>
          <li>⁠Backpacks , crossbags, or any forms of luggage are not permitted within the doors, guests are requested to not carry any such luggage, ladies are permitted to have a clutch or a handbag, but are requested to not carry any contraband (liqor, loose cigarettes, narcotics, weapons, pepper spray, tasers or any prohibited weapons) </li>
          <li>Access: It is mandatory to have a Zt loyalty program, and to be added to the “Guest list”. Non members of the program and members of the program not enlisted in the guest list would be denied entry to the space. </li>
          <li> “Club” Card allows access to only to the floor. “Premium”, “VIP” & “Elite” cards offer access to table or bottle service in any zones as per availability. Access to the zones are limited as per booking. Cigar lounge access is exclusive to VIP and Skybox area guests only </li>
          <li>Carrying or Possession of any forms of weapons, sharp tools, Firearms (licensed or otherwise) is strictly not permitted within the premises</li>
          <li>Behavioral Expectations: Certain behaviors might be prohibited, such as fighting, harassment, or excessive intoxication(management reserves the right, to stop service of alcohol if found to be heavily intoxicated). In the event of any such incidents the guests involved will be evicted from the venue blacklisted preventing future entry to the venue. </li>
          <li>⁠Guests are requested to assign a designated driver, In the event there is none, we would be happy to call you a cab or arrange alternative forms of transport at guest’s expense . In no way or form does the club promote driving under influence, and are requested to comply with the security teams request to preventing you from DUI </li>
          <li>Security checks or pat-downs may be conducted at the entrance for safety reasons.</li>
        </ul>
    </div>
  </div>
  <app-footer [isHomePage]="true"></app-footer>
</div>
