export const CONSTANTS = {
  whatsAppLink: 'https://api.whatsapp.com/send?phone=',
  noDataError: 'No Data found',
  brandName: 'ztloyalty.in',
  purchasesTypeList: [
    {
      label: 'Food',
      value: 'food'
    },
    {
      label: 'Rooms',
      value: 'rooms'
    },
    {
      label: 'Subscriptions',
      value: 'subscriptions'
    },
    {
      label: 'Events',
      value: 'events'
    }
  ],
  arrivalTime:[{
    timeValue: '2:00 PM'
  },{
    timeValue: '3:00 PM'
  },{
    timeValue: '4:00 PM'
  },{
    timeValue: '5:00 PM'
  },{
    timeValue: '6:00 PM'
  },{
    timeValue: '7:00 PM'
  },{
    timeValue: '8:00 PM'
  },{
    timeValue: '9:00 PM'
  },{
    timeValue: '10:00 PM'
  },{
    timeValue: '11:00 PM'
  }
]
  // name: 'KAPIL Batnagar',
  // address: '#403, Sharadha arcade 2nd cross 2nd main. panel, East Mumbai',
  // pin: '400001',
  // mobileNo: '7259392635',
  // email: 'info@zodiak.com',
  // subscription: {
  //   plan: 'PLATINUM',
  //   expires: '12-Jan-2024',
  // },
  // HOME_CAROUSEL_LIST: [
  //   {
  //     image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/banner/CORRIDOR-compressed.jpg',
  //     alt: '',
  //   },
  //   {
  //     image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/banner/htg_outside-compressed.jpg',
  //     alt: '',
  //   },
  //   {
  //     image:  'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/banner/wlinside-compressed.jpg',
  //     alt: '',
  //   },
  // ],
  // hotelsImagesList: [
  //   {
  //     image:
  //       'https://cf.bstatic.com/xdata/images/hotel/max500/335865449.jpg?k=5393edc0d3dcaffaae6d0f8746032cee743f330f1133342faca526fbb3247037&o=&hp=1',
  //     alt: '',
  //   },
  //   // {
  //   //     image: 'https://cf.bstatic.com/xdata/images/hotel/max500/335865449.jpg?k=5393edc0d3dcaffaae6d0f8746032cee743f330f1133342faca526fbb3247037&o=&hp=1',
  //   //     alt: ''
  //   // },
  //   {
  //     image:
  //       'https://pix8.agoda.net/hotelImages/437/43775/43775_16040614390041350417.jpg?ca=6&ce=1&s=450x450',
  //     alt: '',
  //   },
  //   {
  //     image:
  //       'https://cf.bstatic.com/xdata/images/hotel/max1024x768/339155957.jpg?k=e30a8d7293733f54c2c6ec5d226f6e2351f9575b91f67afc827de834eb45b610&o=&hp=1',
  //     alt: '',
  //   },
  // ],
  // gallery_grid: {
  //   left_image1: {
  //     image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/285310524.jpg',
  //     alt:''
  //   },
  //   left_image2: {
  //     image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/289569009.jpg',
  //     alt:''
  //   },
  //   right_image: {
  //     image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/285310459.jpg',
  //     alt:''
  //   },
  //   bottom_image: [
  //     {
  //       image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/285644670.jpg',
  //       alt:''
  //     },
  //     {
  //       image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/285644673.jpg',
  //       alt:''
  //     },
  //     {
  //       image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/289568481.jpg',
  //       alt:''
  //     },
  //     {
  //       image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/289568600.jpg',
  //       alt:''
  //     },
  //     {
  //       image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/289569239.jpg',
  //       alt:''
  //     }
  //   ],
  //   total_count:10
  // },
//   photoGallery: [{
//     image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/OUTSIDE+HTG.jpg',
//     alt:''
//   },{
//     image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/reception+area.jpg',
//     alt:''
//   },{
//     image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/reception+entrance.jpg',
//     alt:''
//   },{
//     image:'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/reception.jpg',
//     alt:''
//   },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/standard+suite+with+twin+bed.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/standard+suite+with+twin+bed2.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/standard+suite+with+twin+bed3.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/standard+suite+with+twin+bed4.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/standard+suite+with+twin+bed5.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/standard+suite+with+twin+bed6.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/CORRIDOR.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/Executive+Suite3.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/Executive+Suite4.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/Executive+Suite5.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/Executive+Suite6.jpg',
//   alt:''
// },
// {
//   image: 'https://zodiak-crm-dev.s3.ap-south-1.amazonaws.com/gallery/ht/gallery2/Executive+Suite7.jpg',
//   alt:''
// }
// ],
// photoGalleryDetails: [
//   {
//     small:'',
//     medium:'',
//     big:''
//   }
// ],
  // dummyHotelList: [
  //   {
  //     id:1,
  //     name: 'Hotel Green Park Suites',
  //     image: 'https://cf.bstatic.com/xdata/images/hotel/square600/289435855.webp?k=6eb6dce6af2ad37878b9eeb3bd25a0b1bd080f8c878823a75017fb90183c3de9&o=&s=1',
  //     roomType: 'Delux Suite',
  //     distance: '0.5 Km from centre',
  //     category: ['Private suite', '1 bedroom','1 Bathroom','1 living room', '51 m2 1 extra large double bed'],
  //     benefit: 'Breakfast Included',
  //     priceAlert: 'Only 1 left at this price on our site',
  //     price: {
  //       description: '30 nights, 2 adults',
  //       price: '18000',
  //       taxes: '21600'
  //     }
  //   },
  //   {
  //     id:2,
  //     name: 'FOUR SQUARE COMFORTS',
  //     image: 'https://cf.bstatic.com/xdata/images/hotel/square600/377927841.webp?k=2b9c34e5e2fa3bc952313b1d1e0cf6ffd7d92b712838059b0b1f740ffc0e3b9a&o=&s=1',
  //     roomType: 'Delux Suite',
  //     distance: '0.5 Km from centre',
  //     category: ['Private suite', '1 bedroom','1 Bathroom'],
  //     benefit: 'Breakfast Included',
  //     priceAlert: 'Only 1 left at this price on our site',
  //     price: {
  //       description: '20 nights, 2 adults',
  //       price: '19000',
  //       taxes: '11600'
  //     }
  //   },
  //   {
  //     id:3,
  //     name: 'Fortune Inn Valley View, Manipal - Member ITC\'s Hotel Group',
  //     image: 'https://cf.bstatic.com/xdata/images/hotel/square600/407629584.webp?k=1f5962219dac76f3f25ebca6e55b47d8b2eacc8f3b2a774276d5ad7c1df7d930&o=&s=1',
  //     roomType: 'Delux Suite',
  //     distance: '0.5 Km from centre',
  //     category: ['Private suite', '1 bedroom','1 Bathroom','1 living room', '51 m2 1 extra large double bed'],
  //     benefit: 'Breakfast Included',
  //     priceAlert: 'Only 1 left at this price on our site',
  //     price: {
  //       description: '30 nights, 2 adults',
  //       price: '18000',
  //       taxes: '21600'
  //     }
  //   },
  //   {
  //     id:4,
  //     name: 'Aryaan Resort',
  //     image: 'https://cf.bstatic.com/xdata/images/hotel/square600/355701959.webp?k=76580356a78a39593cc1a4865b0563f64639fee03048d5952331784490c9a60a&o=&s=1',
  //     roomType: 'Delux Suite',
  //     distance: '0.5 Km from centre',
  //     category: ['Private suite', '1 bedroom','1 Bathroom','1 living room', '51 m2 1 extra large double bed'],
  //     benefit: 'Breakfast Included',
  //     priceAlert: 'Only 1 left at this price on our site',
  //     price: {
  //       description: '30 nights, 2 adults',
  //       price: '18000',
  //       taxes: '21600'
  //     }
  //   }
  // ],
  // timeLineData: {
  //   points: [
  //     {
  //       point: 0,
  //       info: '',
  //     },
  //     {
  //       point: 100,
  //       info: 'free Breakfast'
  //     },
  //     {
  //       point: 300,
  //       info: 'Free Dining'
  //     }
  //   ],
  //   loyalty: 54
  // },
  // timeLineFrequency: 200,
 
};
