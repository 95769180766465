import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-dining-filter',
  templateUrl: './dining-filter.component.html',
  styleUrls: ['./dining-filter.component.scss']
})
export class DiningFilterComponent implements OnInit {
  restaurantMenuList:any;
  @Output() selectedMenu:EventEmitter<string> = new EventEmitter<string>();
  constructor(private commonService: CommonService, private apiService: ApiService){}

  ngOnInit(): void {
    const self = this;
   const data:any = this.commonService.getRestaurantData();
   this.apiService.getRestaurantMenuList(data._id).subscribe({
    next(response: any) {
      if (response.body) {
       const { body } = response.body;
        const updatedMenuList =  response.body.map((v: any) => ({
          ...v,
          isSelected: false,
        }));
        self.restaurantMenuList = updatedMenuList;
      }
    },
    error(error) {
      self.commonService.updateAlertData(error);
    },
  })
  }
  getFilteredProduct(item: any) {
    this.restaurantMenuList.forEach((element:any) => {
      if(element._id === item._id) {
        item.isSelected = !item.isSelected
      } else {
        element.isSelected = false;
      }
    });
    if(item.isSelected) {
      this.selectedMenu.emit(item._id);
    } else {
      this.selectedMenu.emit('');
    }
  }
}
