<div class="slideshow" >
    <div class="slideshow-container">
        <div class="mySlides slideshow__fade" *ngFor="let slideData of carouselList; let i= index" (click)="selectedBanner.emit(slideData)">
            <img class="ss" [src]="slideData?.banner_url" style="width:100%">
        </div>
        <span *ngIf="carouselList.length>1" class="prev" (click)="plusSlides(-1)">&#10094;</span>
        <span *ngIf="carouselList.length>1" class="next" (click)="plusSlides(1)">&#10095;</span>
    </div>
    <br>
    <div style="text-align:center" class="dot-container" *ngIf="carouselList.length>1">
        <span class="dot" (click)="currentSlide(i+1)" *ngFor="let slideData of carouselList; let i= index"></span>
    </div>
</div>