import { Router } from '@angular/router';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-dining-gallery',
  templateUrl: './dining-gallery.component.html',
  styleUrls: ['./dining-gallery.component.scss']
})
export class DiningGalleryComponent {
@Input() galleryItemList!:any;
constructor( private route: Router) { 
}

openGallery() {
  this.route.navigate(['dining/gallery-details']);
}
}
