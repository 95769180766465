<ng-template #MsgRef>
    <span class="header__category" (click)="goToCatogory(category)" [ngClass]="{'header__category-active': category.isActive}" *ngFor="let category of headerCategory; let i= index">
        <img [src]="category.icon" width="20" height="20"/>
        <span>{{category.name}}</span>
    </span>
 </ng-template>
<div class="header" #header>
    <div class="header__registration container">
      <div (click)="goToHome()" class="header__wrapper">
       <!-- <span class="brand-logo">{{brandName}}</span> -->
       <img  class="brand-logo" src="../../../../../assets/images/logo/logo.png" />
       <!-- <img  class="brand-logo" src="../../../../../assets/images/logo/mobile_logo.png" /> -->
        <span class="header__category-desktop" *ngIf="!isNavigationRequired">
            <ng-template  [ngTemplateOutlet]="MsgRef"></ng-template>
        </span>
      </div>
        <div *ngIf="!isLoggedIn">
            <a class="header__btn" (click)="gotToAuth(auth)" [ngClass]="{'header__btn-active': auth.isActive}"  *ngFor="let auth of authList; let i= index">{{auth.name}}</a>
        </div>
        <div class="header__auth-user"  *ngIf="isLoggedIn ">
            <div class="header__auth-user--notification" *ngIf="isNotificationRequired&&orderListForFeedback?.length>0">
             <div (click)="showNotification=!showNotification">
                <!-- <i class="fa fa-bell" aria-hidden="true"></i> -->
                <img  class="" src="../../../../../assets/images/notification.svg" />
                <span class="header__auth-user--badge">{{orderListForFeedback?.length}}</span>
             </div>
                <div class="header__auth-user--list" *ngIf="showNotification">
                   <div class="header__auth-user--item" *ngFor="let orderForFeedback of orderListForFeedback">
                    <h2>{{orderForFeedback.shop_name}}</h2>
                    <span class="header__auth-user--info"><strong>Order No: </strong>{{orderForFeedback.order_no}}</span>
                    <span class="header__auth-user--info"><strong>Date: </strong>{{commonService.formatDate(orderForFeedback.order_date)}}</span>
                    <button class="app-primery-btn" (click)="goToFeedback(orderForFeedback);showNotification=!showNotification">Provide feedback</button>
                   </div>
                </div>
            </div>
            <div class="header__circle" *ngIf="userDetails?.name" (click)="goTo('/profile')">{{getSmallName(userDetails?.name)}}</div>
            <div class="header__user" (click)="goTo('/profile')">
             <span>{{userDetails?.name}}</span>
            <span class="header__user-membership">{{userDetails?.userDetails?.subscription?.plan}}</span>
            </div>
        </div>
    </div>
    <div class="header__nav" *ngIf="!isNavigationRequired">
        <ng-template  [ngTemplateOutlet]="MsgRef"></ng-template>
    </div>
    <!-- <app-alert [data]="alertData" *ngIf="alertData&&isAlertOpen"></app-alert> -->
    <app-alert></app-alert>
</div>
