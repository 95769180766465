import { CONSTANTS } from './../../constans';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {
  whatsappLink!:string;
  @Input() mobileNo!: string;

  ngOnInit() {
    this.whatsappLink = CONSTANTS.whatsAppLink +  this.mobileNo;
  }
}
