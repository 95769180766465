import { Component } from '@angular/core';

@Component({
  selector: 'app-term-of-service',
  templateUrl: './delete-my-account.component.html',
  styleUrls: ['./delete-my-account.component.scss']
})
export class DeleteMyAccountComponent {

}
