import { environment } from './../../../environments/environment.prod';
export const APIKEYS = {
    METHOD_POST: 'post',
    register: {
        endPoint: '/users',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    login: {
        endPoint: '/users/login',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    logout: {
        endPoint: '/users/logout',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    profileDetails: {
        endPoint: '/users',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    addAddress: {
        endPoint: '/users/add_address',
        method: 'put',
        baseUrl: environment.baseUrl
    },
    deleteAddress: {
        endPoint: '/users/delete_address',
        method: 'put',
        baseUrl: environment.baseUrl
    },
    defaultAddress: {
        endPoint: '/users/default_address',
        method: 'put',
        baseUrl: environment.baseUrl
    },
    updateProfile: {
        endPoint: '/users',
        method: 'patch',
        baseUrl: environment.baseUrl
    },
    getSubscriptions: {
        endPoint: '/subscriptions',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    getLocation: {
        endPoint: '/locations',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    getFilteredLocation: {
        endPoint: '/locations',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    sendOtpCode: {
        endPoint: '/users/send-otp-code',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    verifyOtpCode: {
        endPoint: '/users/verify-otp-code',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    resetPassword: {
        endPoint: '/users/reset-password',
        method: 'put',
        baseUrl: environment.baseUrl
    },
    homePage: {
        endPoint: '/settings',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    roomSearch: {
        endPoint: '/rooms',
        method: 'post',
        baseUrl: environment.alternateBaseUrl
    },
    hotelSearchDetails: {
        endPoint: '/rooms',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    restaurantList: {
        endPoint: '/restaurants',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    restaurantDetails: {
        endPoint: '/restaurants',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    restaurantSummary: {
        endPoint: '/rooms/booking/summary',
        method: 'post',
        baseUrl: environment.alternateBaseUrl
    },
    payment: {
        endPoint: '/payments/orderid',
        method: 'post',
        baseUrl: environment.alternateBaseUrl
    },
    foodMenuList: {
        endPoint: '/menucategory',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    productListInRestaurant: {
        endPoint: '/products/list',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    timelineMilestones: {
        endPoint: '/milestones',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    purchasesFoodOrderList: {
        endPoint: '/orders',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    purchasesFoodOrderDetails: {
        endPoint: '/orders',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    purchasesSubscriptionsList: {
        endPoint: '/subscriptions/customers',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    purchasesRoomsList: {
        endPoint: '/orders/customers',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    purchasesRoomDetails: {
        endPoint: '/orders',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    purchasesEventOrderList: {
        endPoint: '/event/bookings',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    purchasesEventOrderDetails: {
        endPoint: '/event/bookings/',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    loyaltiesPointsList: {
        endPoint: '/loyalties',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    placeFoodOrder: {
        endPoint: '/orders',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    foodOrderSummery: {
        endPoint: '/orders/summary',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    uploadProfileImage: {
        endPoint: '/users/update-profile-pic',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    paymentVerification: {
        endPoint: '/payments/verification',
        method: 'post',
        baseUrl: environment.alternateBaseUrl
    },
    getEventVenue: {
        endPoint: '/event-venues',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    getEventList: {
        endPoint: '/events',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    getEventDetails: {
        endPoint: '/events',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    getEventAreaTypeForTableBooking: {
        endPoint: '/events',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    getEventAvailableTableForBooking: {
        endPoint: '/events',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
    getEventSummery: {
        endPoint: '/events/booking/summary',
        method: 'post',
        baseUrl: environment.alternateBaseUrl
    },
    getEventOrderIdForPayment: {
        endPoint: '/payments/orderid',
        method: 'post',
        baseUrl: environment.alternateBaseUrl
    },
    getOrderListForFeedBack: {
        endPoint: '/feedback/orders',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    getAttributeListForFeedBack: {
        endPoint: '/feedback/attributes',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    sendFeedback: {
        endPoint: '/feedback/send',
        method: 'post',
        baseUrl: environment.baseUrl
    },
    requestGuest: {
        endPoint: '/guest/request-guest',
        method: 'PUT',
        baseUrl: environment.baseUrl
    },
    getAutocompleteLocation: {
        endPoint: '/place/autocomplete',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    getLocationDetails: {
        endPoint: '/place/details',
        method: 'get',
        baseUrl: environment.baseUrl
    },
    getPaymentStatus: {
        endPoint: '/payments/check-status',
        method: 'get',
        baseUrl: environment.alternateBaseUrl
    },
}