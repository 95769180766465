import { BannerComponent } from './components/banner/banner.component';
import { PhotoGalleryDetailsComponent } from './components/photo-gallery-details/photo-gallery-details.component';
import { PhotoGalleryComponent } from './components/photo-gallery/photo-gallery.component';
import { AddressComponent } from './components/address/address.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'swiper/angular';
import { ModalComponent } from './components/modal/modal.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import { GoogleMapsModule } from '@angular/google-maps'
import { PhotoGridComponent } from './components/photo-grid/photo-grid.component';
import { MapComponent } from './components/map/map.component';
import { FacilitiesComponent } from './components/facilities/facilities.component';
import { NgOtpInputModule } from 'ng-otp-input';
import { HttpClientModule } from '@angular/common/http';
import { AlertComponent } from './components/alert/alert.component';
import { ChatComponent } from './components/chat/chat.component';
import { LoaderComponent } from './components/loader/loader.component';
import { ScrollDirective } from './directives/scroll.directive';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { AddressListComponent } from './components/address-list/address-list.component';
import { OrderConfirmationComponent } from './components/order-confirmation/order-confirmation.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { DiningDetailsComponent } from './components/dining-details/dining-details.component';
import { DiningHeaderComponent } from './components/dining-header/dining-header.component';
import { DiningGalleryComponent } from './components/dining-gallery/dining-gallery.component';
import { DiningFilterComponent } from './components/dining-filter/dining-filter.component';

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    AddressComponent,
    PhotoGalleryComponent,
    PhotoGalleryDetailsComponent,
    PhotoGridComponent,
    MapComponent,
    FacilitiesComponent,
    BannerComponent,
    AlertComponent,
    ChatComponent,
    LoaderComponent,
    ScrollDirective,
    SafeHtmlPipe,
    AddressListComponent,
    OrderConfirmationComponent,
    DiningDetailsComponent,
    DiningHeaderComponent,
    DiningGalleryComponent,
    NoDataComponent,
    DiningFilterComponent
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgbModule,
    NgxGalleryModule,
    GoogleMapsModule,
    NgOtpInputModule,
    HttpClientModule,
    // HttpModule,
  ],
  providers: [],
  bootstrap: [],
  exports:[
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SwiperModule,
    NgbModule,
    HeaderComponent,
    FooterComponent,
    ModalComponent,
    AddressComponent,
    NgxGalleryModule,
    GoogleMapsModule,
    PhotoGalleryComponent,
    PhotoGalleryDetailsComponent,
    PhotoGridComponent,
    MapComponent,
    FacilitiesComponent,
    NgOtpInputModule,
    BannerComponent,
    AlertComponent,
    ChatComponent,
    LoaderComponent,
    ScrollDirective,
    SafeHtmlPipe,
    AddressListComponent,
    DiningHeaderComponent,
    NoDataComponent,
    DiningFilterComponent
  ]
})
export class SharedModule { }
