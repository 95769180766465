<app-header></app-header>
<div class="order-confirmation">
    <!-- <div *ngIf="OrderSuccessData?.status != 'Error'">
        <p class="order-confirmation__success" *ngIf="!OrderSuccessData?.title">Thanks for ordering with {{brandName}}</p>
        <p class="order-confirmation__success" *ngIf="OrderSuccessData?.title">{{OrderSuccessData?.title}}</p>
        <p *ngIf="OrderSuccessData?.razorpay_order_id"><strong>Order Id :</strong> {{OrderSuccessData?.razorpay_order_id}}</p>
        <p *ngIf="!OrderSuccessData?.modeOfPayment"><strong>Mode of Payment:</strong> {{ 'Online Payment' }}</p>
        <p *ngIf="OrderSuccessData?.modeOfPayment"><strong>Mode of Payment:</strong> {{ OrderSuccessData?.modeOfPayment }}
        </p>
    </div>
    <p class=" order-confirmation__success order-confirmation__error" *ngIf="OrderSuccessData?.status === 'Error'">
        Sorry, unfortunately your payment failed</p> -->

        <div>
            <p class="order-confirmation__success" [ngClass]="{'order-confirmation__error': (orderData?.isError)}">{{orderData?.title}}</p>
            <p *ngIf="orderData?.orderId"><strong>Order Id :</strong> {{orderData?.orderId}}</p>
            <p *ngIf="orderData?.modeOfPayment"><strong>Mode of Payment:</strong> {{orderData?.modeOfPayment }}
            </p>
        </div>
        <!-- <p class=" order-confirmation__success order-confirmation__error" *ngIf="OrderSuccessData?.status === 'Error'">
            Sorry, unfortunately your payment failed</p> -->
    <p class="order-confirmation__link" (click)="goToHome()">Click To Home</p>
</div>

<app-footer></app-footer>