import { Injectable, Injector } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BehaviorSubject, Subject } from 'rxjs';
import { CONSTANTS } from '../modules/shared/constans';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  //list of router where header nav not required
  headerNavigationNotRequiredPageList = [
    'login',
    'register',
    'otp',
    'forgot-password',
  ];
  isHeaderNavigationRequired$ = new BehaviorSubject(false);
  isLoggedInUser$ = new BehaviorSubject(false);
  isUserDataUpdated$ = new BehaviorSubject(false);
  alertDataUpdated$ = new Subject();
  loader$ = new BehaviorSubject(false);
  nextPageAfterAuth!: string;
  authList = [
    {
      name: 'Login',
      path: 'authentication/login',
      isActive: true,
    },
    {
      name: 'Register',
      path: 'authentication/register',
      isActive: false,
    },
  ];
  headerCategory = [
    {
      name: 'Stays',
      icon: './../../../assets/images/headers/bed.svg',
      path: 'booking/stay',
      isActive: false,
    },
    {
      name: 'Food + Dinner',
      icon: './../../../assets/images/headers/food.svg',
      path: 'dining',
      isActive: false,
    },
    {
      name: 'Events',
      icon: './../../../assets/images/headers/event.svg',
      path: 'event',
      isActive: false,
    },
  ];
  forgotPasswordData = {
    phone_number: '',
    otp_id: '',
    token: '',
  };
  roomData = {
    locationList: [],
    hotelDto: {
      location_id: '',
      from_date: '',
      to_date: '',
      no_of_rooms: 0,
      no_adults: 0,
      no_of_childrens: 0,
      child_age: '',
      mobile: {
        checkinDate: {},
        checkoutDate: {},
      },
    },
    childrenAgesList: [],
    hotelId: '',
    selectedDate: '',
    selectedCity: '',
    selectedOcupancy: '',
  };
  restaurantData = {
    _id: '',
    restaurantHeaderData: {},
    cartItem: {},
  };
  galleryDetailsData: any = [];

  eventData = {
    eventId: ''
  }
  orderConfirmationMessage:any = {
    title: `Thanks for ordering with ${CONSTANTS.brandName}`,
    orderId: '',
    modeOfPayment: '',
    isError: false
  }

  constructor(
    private router: Router,
    private deviceService: DeviceDetectorService,
    private injector: Injector
  ) {
    this.getActivatedRoute();
  }

  updateHeaderNavigationRequired(data: any) {
    this.isHeaderNavigationRequired$.next(data);
  }

  getActivatedRoute() {
    this.router.events.subscribe((event: any) => {
      if (event instanceof NavigationEnd) {
        const url = this.router.url.split('/');
        this.headerNavigationNotRequiredPageList.indexOf(url[2]) != -1
          ? this.updateHeaderNavigationRequired(true)
          : this.updateHeaderNavigationRequired(false);
      }
    });
  }
  isDesktop() {
    return this.deviceService.isDesktop();
  }
  updateLoginStatus(data: boolean) {
    this.isLoggedInUser$.next(data);
  }
  updatedUserData(data:boolean) {
    this.isUserDataUpdated$.next(data);
  }
  updateAlertData(data: any) {
    this.alertDataUpdated$.next(data);
  }
  setUserDetails(userDetails: any) {
    localStorage.setItem('userDetails', JSON.stringify(userDetails));
    this.updateLoginStatus(true);
  }
  getUserDetails() {
    const data: any = localStorage.getItem('userDetails');
    return JSON.parse(data);
  }

  isUserAuthenticated() {
    const userData: any = this.getUserDetails();
    if (userData) return !!userData.token;
    return false;
  }
  getSessionToken() {
    const userData: any = this.getUserDetails();
    return userData ? userData.token : '';
  }
  setForgotPasswordData(data: any) {
    this.forgotPasswordData = data;
  }
  resetForgotPasswordData() {
    this.forgotPasswordData = {
      phone_number: '',
      otp_id: '',
      token: '',
    };
  }
  getForgotPasswordData() {
    return this.forgotPasswordData;
  }
  getRoomData() {
    const data: any = sessionStorage.getItem('roomData');
    return JSON.parse(data);
  }
  setRoomData(data: any) {
    sessionStorage.setItem('roomData', JSON.stringify(data));
  }
  clearRoomData() {
    sessionStorage.removeItem('roomData');
    this.roomData = {
      locationList: [],
      hotelDto: {
        location_id: '',
        from_date: '',
        to_date: '',
        no_of_rooms: 0,
        no_adults: 0,
        no_of_childrens: 0,
        child_age: '',
        mobile: {
          checkinDate: {},
          checkoutDate: {},
        },
      },
      childrenAgesList: [],
      hotelId: '',
      selectedDate: '',
      selectedCity: '',
      selectedOcupancy: '',
    };
  }

  setRestaurantData(data: any) {
    this.restaurantData = data;
    this.clearRestaurantData();
    sessionStorage.setItem(
      'restaurantData',
      JSON.stringify(this.restaurantData)
    );
  }
  getRestaurantData() {
    const data: any = sessionStorage.getItem('restaurantData');
    return JSON.parse(data);
  }
  clearRestaurantData() {
    sessionStorage.removeItem('restaurantData');
  }
  setLoader(data: boolean) {
    this.loader$.next(data);
  }

  setGalleryDataForGalleryDetails(galleryData: any) {
    this.galleryDetailsData = {};
    this.galleryDetailsData = galleryData;
  }
  getGalleryDataForGalleryDetails() {
    return this.galleryDetailsData;
  }

  getPhotoGrid(galleryList: any) {
    const updatedGalleryListForBanner: Array<{
      banner_url: string;
      alt: string;
    }> = [];
    const updatedGalleryListForDetails: Array<{
      small: string;
      medium: string;
      big: string;
    }> = [];
    galleryList.forEach((element: string, index: number) => {
      updatedGalleryListForBanner.push({
        banner_url: element,
        alt: index.toString(),
      });
      updatedGalleryListForDetails.push({
        small: element,
        medium: element,
        big: element,
      });
    });
    return { updatedGalleryListForBanner, updatedGalleryListForDetails };
  }
  setNextPageAfterAuth(pageName: string) {
    this.nextPageAfterAuth = pageName;
  }
  getNextPageAfterAuth() {
    return this.nextPageAfterAuth;
  }
  clearNextPageAfterAuth() {
    this.nextPageAfterAuth = '';
  }

  public formatDate(date: any, isTimeRequired:boolean = false) {
    const newDate = new Date(date);
    const formattedDate = newDate
      .toLocaleTimeString('en-GB',  {
        day: 'numeric',
        month: 'short',
        year: 'numeric',
        hour: 'numeric',
        minute: 'numeric'
      })
      .replace(/ /g, '-')
      .replace(/,-/g, ' ');
    return isTimeRequired ? formattedDate.split(" ")[0] : formattedDate;
  }

  formatHour(hour: any, minutes: any) {
    let minutesPadded = minutes.toString().padStart(2, '0');
    return {
      timeValue:
        hour < 12 || hour == 24
          ? (hour < 24 ? hour : 12) + `:${minutesPadded} AM`
          : (hour - 12 || 12) + `:${minutesPadded} PM`,
    };
  }

  getTimeDDL(intervalMinutes: any) {
    let timesDDL = [];
    let hour = 1;
    while (hour <= 24) {
      timesDDL.push(this.formatHour(hour, 0));

      if (hour < 24) {
        let steps = 60 / intervalMinutes;
        let i = 1;
        while (i < steps) {
          let minutes = intervalMinutes * i;
          timesDDL.push(this.formatHour(hour, minutes));
          i++;
        }
      }

      hour++;
    }
    return timesDDL;
  }
  clearLoaclAndSessionData() {
    this.clearSessionData();
    localStorage.clear();
  }
  clearSessionData() {
    sessionStorage.clear();

  }
  setEventData(data:any) {
    sessionStorage.setItem('eventData',JSON.stringify(data))
  }
  getEventData() {
    let data:any = sessionStorage.getItem('eventData')
    return JSON.parse(data)
  }
  clearEventData() {
    sessionStorage.removeItem('eventData');

  }
  setFeedbackData(data:any) {
    sessionStorage.setItem('feedback',JSON.stringify(data))
  }
  getFeedbackData() {
    let data:any = sessionStorage.getItem('feedback')
    return JSON.parse(data)
  }
  setOrderConfirmationData(data:any) {
    this.clearOrderConfirmationData();
    this.orderConfirmationMessage = {
      title: data&&data.title ? data.title :  this.orderConfirmationMessage.title,
      orderId: data&&data.orderId ? data.orderId : '',
      modeOfPayment: data&&data.modeOfPayment ? data.modeOfPayment : '',
      isError: data&&data.isError ? data.isError : false
    }
    sessionStorage.setItem('summery',JSON.stringify(this.orderConfirmationMessage));
  }
  getOrderConfirmationData() {
    let data:any = sessionStorage.getItem('summery')
    return JSON.parse(data)
  }
  clearOrderConfirmationData() {
    this.orderConfirmationMessage = {
      title: `Thanks for ordering with ${CONSTANTS.brandName}`,
      orderId: '',
      modeOfPayment: '',
      isError: false
    }
    sessionStorage.removeItem('summery')
  }

  redirectToPage() {
    const nextPageData = this.getNextPageAfterAuth();
    let nextPage = '';
    if (nextPageData) {
      nextPage = nextPageData;
      this.clearNextPageAfterAuth();
    } else {
      nextPage = '/';
    }
    this.router.navigate([nextPage], { replaceUrl: true });
  }

  updateProfileDetails() {
    const self = this;
    const apiService = this.injector.get(ApiService);
    apiService.getProfileDetails().subscribe({
      next(response: any) {
        if (response.body) {
          const data = response.body;
          // const defaultAddress = data.address[0].address;
          const userData = self.getUserDetails();
          self.setUserDetails({ ...userData, userDetails:data });
          self.redirectToPage();
        }
      },
      error(error) {
        self.updateAlertData(error);
      },
    });
  }

  setSettingsData(data:any) {
    localStorage.setItem('settings', JSON.stringify(data))
  }
  getSettingsData() {
    const data:any = localStorage.getItem('settings')
    return JSON.parse(data)
  }
}
