<app-modal title="Address" #modalRef>
    <app-address (addressUpdated)="onAddressUpdated($event)"></app-address>
</app-modal>
<div class="address-list__manage-address">
 <div *ngIf="!showAddAddress">
    <h2 (click)="openAddressModal()">Manage Address +</h2>
    <p>Click + to add address</p>
    <div class="address-list__field-address" *ngFor="let address of addressList">
        <div>
            <button class="address-list__address-default" *ngIf="address.default">DEFAULT</button>
            <div class="address-list__name-section">
                <span>{{address?.title}}</span>
                <span>{{address?.mobile_number}}</span>
            </div>
            <p>
                {{address.address.street_address}}, {{address.address.city}}, {{address.address.state}},
                {{address.address.country}} - <strong>{{address.address.zip}}</strong>
            </p>
            <p>
                <span *ngIf="address.address?.location" >Location:</span>
                <span>{{address.address?.location}}</span>
            </p>
        </div>
        <div *ngIf="!isModifyAddressRequired">
            <button class="app-secondry-btn" (click)="selectedAddress(address)">Select</button>
        </div>
        <div *ngIf="isModifyAddressRequired">
        <div (click)="$event.stopPropagation(); myDrop.open();" id="dropdownManual">&#xFE19;</div>
        <div ngbDropdown #myDrop="ngbDropdown" class="address-list__dropdown">
            <div ngbDropdownMenu aria-labelledby="dropdownManual">
                <button ngbDropdownItem (click)="deleteAddress(address)">Delete</button>
                <button ngbDropdownItem (click)="makeAddressDefault(address)">Default</button>
            </div>
        </div>
    </div>
    </div>
 </div>
    <app-address (addressUpdated)="onAddressUpdated($event)" *ngIf="showAddAddress"></app-address>
</div>