import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {
  isLoading!:boolean;
  constructor(private commonService: CommonService){}
 
    ngOnInit(): void {
    this.commonService.loader$.subscribe(isLoading => {
      this.isLoading = isLoading;
    })
  }
}
