<div class="dining-header__hotel-info card">
    <div class="app-row-spacebetween dining-header__hotel-mobile">
        <div class="app-column">
            <span class="heading-bold">{{headerData.name}}</span>
            <span class="prag-normal">{{headerData.description}}</span>
            <div *ngIf="headerData?.type!='event'">
                <span class="dining-header__hotel-timing" [ngStyle]="{'color': headerData?.isOpen_text_color}">{{headerData?.isOpen_text}}:</span>
                <span class="prag-normal">{{headerData?.booking_start_time_display + ' - ' + headerData?.booking_end_time_display +
                    '(Today)'}}</span>
            </div>
        </div>
        <div>
            <div>
                <button *ngIf="isButton && headerData?.isOpen===1 && headerData?.type!='event'" class="app-secondry-btn" (click)="buttonClicked.emit()" >ORDER ONLINE</button>
                <div class="dining-header__search app-row-all-center" *ngIf="isSearch">
                    <div *ngIf="!isDesktop">
                        <button class="app-secondry-btn"
                            (click)="$event.stopPropagation(); myDrop.open();">Filter</button>
                        <div ngbDropdown #myDrop="ngbDropdown" class="dining-header__search--dropdown">
                            <div ngbDropdownMenu aria-labelledby="dropdownManual">
                                <app-dining-filter ngbDropdownItem (selectedMenu)="changedFilter.emit($event)"></app-dining-filter>
                            </div>
                        </div>
                    </div>

                    <div class="input-group flex-nowrap">
                        <span class="input-group-text" id="addon-wrapping">
                            <!-- <i class="fa-brands fa-searchengin"></i> -->
                        </span>
                        <span class="dining-header__search--cross" (click)="clearSearchedText()">X</span>
                        <input type="text" class="form-control" placeholder="Search menu" aria-label="search-menu"
                            aria-describedby="addon-wrapping" (ngModelChange)="modelChanged.next($event)"
                            [(ngModel)]="searchedText">
                    </div>
                    <div class="dining-header__cart" *ngIf="!isDesktop && (cartTotalCount>0)" >
                      <div (click)="goToSummery()">
                        <span class="dining-header__cart--total" >{{cartTotalCount}}</span>
                        <img src="../../../../../assets/images/cart.svg" />
                      </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>