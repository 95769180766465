import { ApiService } from 'src/app/services/api.service';
import { CommonService } from './../../../../services/common.service';
import { Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-dining-details',
  templateUrl: './dining-details.component.html',
  styleUrls: ['./dining-details.component.scss'],
})
export class DiningDetailsComponent implements OnInit {
  isDesktop!: boolean;
  restaurantDetails: any;
  restaurantHeaderData: any;
  restaurantAddress!: string;
  galleryList!: any;
  constructor(
    private routes: Router,
    private commonService: CommonService,
    private apiService: ApiService
  ) {}

  ngOnInit(): void {
    const self = this;
    this.isDesktop = this.commonService.isDesktop();
    const data: any = this.commonService.getRestaurantData();
    this.apiService.getRestaurantDetails(data._id).subscribe({
      next(response: any) {
        if (response.body) {
          self.restaurantDetails = response.body;
          const { name, description, booking_start_time_display, booking_end_time_display , isOpen_text_color,isOpen_text, isOpen } =
            self.restaurantDetails;
          self.restaurantHeaderData = {
            name,
            description,
            booking_end_time_display,
            booking_start_time_display,
            isOpen_text,
            isOpen_text_color,
            isOpen,
            type: data.type || ''
          };
          const { street_address, city, state, zip, country } =
            self.restaurantDetails.address;
          self.restaurantAddress = `${street_address} ${city} - ${zip}, ${state} ${country}`;
          self.galleryList = self.commonService.getPhotoGrid(
            self.restaurantDetails.gallery
          );
          const updatedData = {
            restaurantHeaderData: self.restaurantHeaderData,
            _id: data._id,
            type: data.type || ''
          };
          self.commonService.setRestaurantData(updatedData);
          self.commonService.setGalleryDataForGalleryDetails(self.galleryList);
        }
      },
      error(error) {
        self.commonService.updateAlertData(error);
      },
    });
  }

  goTO() {
    this.routes.navigate(['dining/menu-list']);
  }
  goToLink(url: string) {
    window.open(url, '_blank');
  }
}
