
import { Component, OnInit, Input, OnDestroy, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit,OnDestroy  {
  timer:any;
  @Input() carouselList: any;
  @Output() selectedBanner:EventEmitter<any> = new EventEmitter<any>()

  slideIndex = 1;
  slideDataList:any;
  isMorethenOneImage= false;
  constructor() { }
  ngOnDestroy(): void {
    clearInterval(this.timer);
  }

  ngOnInit() {

    setTimeout(() => {
      this.showSlides(this.slideIndex);
    }, 500);

    this.timer = setInterval(() => {
      this.slideIndex++;
      this.showSlides(this.slideIndex);
    }, 5000);
  }

  
  /**
   * 
   * @param slideCount is to show particular slides.
   */
  plusSlides(slideCount: number) {
    this.showSlides(this.slideIndex += slideCount);
  }

  currentSlide(slideCount: number) {
    this.showSlides(this.slideIndex = slideCount);
  }

  showSlides(slideIndex: number) {
    let index: number;
    const sliderId = this.isMorethenOneImage ? 'slideList' : 'mySlides'
    const dotId = this.isMorethenOneImage ? 'slideListDot' : 'dot'
    let slides = document.getElementsByClassName(sliderId) as HTMLCollectionOf<HTMLElement>;
    let dots = document.getElementsByClassName(dotId) as HTMLCollectionOf<HTMLElement>;
    if (slideIndex > slides.length) { this.slideIndex = 1 }
    if (slideIndex < 1) { this.slideIndex = slides.length }
    for (index = 0; index < slides.length; index++) {
      slides[index].style.display = "none";
    }
    for (index = 0; index < dots.length; index++) {
      dots[index].className = dots[index].className.replace(" active", "");
    }
   (slides[this.slideIndex - 1].style.display = "block");
   (dots[this.slideIndex - 1].className += " active");
  }

}
