import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CommonService } from 'src/app/services/common.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-photo-gallery',
  templateUrl: './photo-gallery.component.html',
  styleUrls: ['./photo-gallery.component.scss']
})
export class PhotoGalleryComponent implements OnInit {
  galleryData:any;
  constructor(private router: Router, private commonService: CommonService, private _location: Location) { }
  ngOnInit(): void {
    const { updatedGalleryListForBanner } = this.commonService.getGalleryDataForGalleryDetails();
    this.galleryData = updatedGalleryListForBanner;
  }
  goToDetails() {
    this.router.navigate(['booking/gallery-details'])
  }

  backClicked() {
    this._location.back();
  }

}
