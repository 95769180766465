<div class="facilities__info-list" *ngIf="facilitiesList">
    <div class="facilities__info" *ngFor="let facilities of facilitiesList">
        <!-- <i class="fa-solid fa-user"></i> -->
        <img class="facilities__info--icon"  [src]="facilities.icon"  (error)="$any($event.target).src = '../../../../../assets/images/default_facilities_icon.svg'"/>
        <span>{{facilities.name}}</span>
    </div>
</div>

<!-- <div class="facilities__info-list" *ngIf="!facilitiesList">
    <div class="facilities__info">
        <i class="fa-solid fa-user"></i>
        <span>Parking</span>
    </div>
    <div class="facilities__info">
        <i class="fa-solid fa-user"></i>
        <span>Restorent</span>
    </div>
    <div class="facilities__info">
        <i class="fa-solid fa-user"></i>
        <span>Winr</span>
    </div>
</div> -->