import { ActivatedRoute, Router } from '@angular/router';
import { CONSTANTS } from './../../constans';
import { Component, Input, OnInit } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-order-confirmation',
  templateUrl: './order-confirmation.component.html',
  styleUrls: ['./order-confirmation.component.scss'],
})
export class OrderConfirmationComponent implements OnInit {
  brandName = CONSTANTS.brandName;
  OrderSuccessData: any;
  orderSummeryData: any;
  orderData:any;
  constructor(
    private router: ActivatedRoute,
    private route: Router,
    private apiService: ApiService,
    private commonService: CommonService
  ) {}

  ngOnInit(): void {
    this.commonService.clearEventData();
    this.commonService.clearRestaurantData();
    this.commonService.clearRestaurantData();
    this.commonService.clearRoomData();
    this.orderSummeryData = this.commonService.getOrderConfirmationData();
    const transactionId = this.router.snapshot.queryParamMap.get(
      'merchant_transaction_id'
    );
    if (transactionId) {
      this.checkPaymentStatus(transactionId);
    } else {
      this.orderData = this.orderSummeryData;
    }
  }

  goToHome() {
    this.route.navigate(['/']);
  }
  verifyPayment(payload: any) {
    const self = this;
    this.apiService.paymentVerification(payload).subscribe({
      next(response: any) {
        if (response.body) {
          self.OrderSuccessData = self.orderSummeryData;
        }
      },
      error(error) {
        self.commonService.updateAlertData(error);
        self.OrderSuccessData = { ...self.orderSummeryData, status: 'Error' };
      },
    });
  }
  checkPaymentStatus(transactionId: any) {
    const self = this;
    this.apiService.getPaymentStatus(transactionId).subscribe({
      next(response: any) {
        const { code, subCode } = response;
        if (code === 200) {
          if(self.orderSummeryData && self.orderSummeryData.orderId === 'subscribing') {
            self.commonService.updatedUserData(true);
            self.orderSummeryData.orderId = '';
          }
          self.orderData = self.orderSummeryData;
        } else {
          self.constructError();
        }
      },
      error(error) {
        self.constructError();
      },
    });
  }
  constructError() {
    this.orderData = {
      title: 'Sorry, unfortunately your payment failed',
      orderId: '',
      modeOfPayment: '',
      isError: true,
    };
  }
}
