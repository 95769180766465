<div class="privacy">
  <!-- <app-header></app-header> -->
  <div class="container">
    <div class="privacy">
      <h2 class="title">Privacy Policy</h2>
      <p>Last updated: September 27, 2023</p>
      <p>
        At ZTLoyalty, accessible from
        <a href="https://ztloyalty.in/">https://ztloyalty.in/</a>, one of our main
        priorities is the privacy of our visitors. This Privacy Policy document
        contains types of information that is collected and recorded by
        <a href="https://ztloyalty.in/">www.ztloyalty.in</a> and how we use it.
      </p>
      <p>
        If you have additional questions or require more information about our
        Privacy Policy, do not hesitate to contact us.
      </p>
      <h2 class="title">Consent</h2>
      <p>
        By using our website, you hereby consent to our Privacy Policy and agree
        to its terms.
      </p>

      <h2 class="title">Collecting and Using Your Personal Data</h2>
      <h2 class="title">Information we collect</h2>
      <p>
        When you register for an Account, we may ask for your contact
        information, including items such as name , date of birth, address,
        email address, and telephone number
      </p>
      <h2 class="title">How we use your information</h2>
      <p>We use the information we collect in various ways, including to:</p>
      <ul>
        <li>Provide, operate, and maintain our website</li>
        <li>Improve, personalize, and expand our website</li>
        <li>Understand and analyze how you use our website</li>
        <li>
          Communicate with you, either directly or through one of our partners,
          including for customer service, to provide you with updates and other
          information relating to the website, and for marketing and promotional
          purposes
        </li>
        <li>Send you emails</li>
        <li>Sent you text messages</li>
        <li>Sent you notification</li>
      </ul>
      <h2 class="title">Log Files</h2>
      <p>
        <a href="https://ztloyalty.in">www.ztloyalty.in</a> follows a standard
        procedure of using log files. These files log visitors when they visit
        websites. All hosting companies do this as part of hosting services’
        analytics. The information collected by log files include internet
        protocol (IP) addresses, browser type, Internet Service Provider (ISP),
        date and time stamp, referring/exit pages, and possibly the number of
        clicks. These are not linked to any information that is personally
        identifiable. The purpose of the information is for analyzing trends,
        administering the site, tracking users’ movement on the website, and
        gathering demographic information.
      </p>
      <h2 class="title">Security: How we protect your information</h2>
      <p>
        We have implemented appropriate physical, electronic, and managerial procedures to safeguard and help prevent
        unauthorized access to your information and to maintain data security. These safeguards take into account the
        sensitivity of the information that we collect, process and store and the current state of technology. We follow
        generally accepted industry standards to protect the personal information submitted to us, both during transmission
        and once we receive it. The third party service providers with respect to payment gateway and payment processing are
        all validated as compliant with the payment card industry standard (generally referred to as PCI compliant service
        providers).
      </p>
      <p>
        We assume no liability or responsibility for disclosure of your information due to errors in transmission,
        unauthorized third-party access, or other causes beyond our control. You play an important role in keeping your
        personal information secure. You should not share your user name, password, or other security information for your
        ZTLoyalty account with anyone. If we receive instructions using your user name and password, we will consider that you
        have authorized the instructions.
      </p>
      <h2 class="title">Children’s Information</h2>
      <p>
        We encourage parents and guardians to observe, participate in, and/or
        monitor and guide their online activity.
      </p>
      <p>
        <a href="https://ztloyalty.in/">www.ztloyalty.in</a> does not knowingly
        collect any Personal Identifiable Information from children under the
        age of 18. If you think that your child provided this kind of
        information on our website, we strongly encourage you to contact us
        immediately and we will do our best efforts to promptly remove such
        information from our records.
      </p>
      <h2 class="title">Third Party Privacy Policies</h2>
      <p>
        <a href="https://ztloyalty.in/">www.ztloyalty.in’s</a> Privacy Policy
        does not apply to other advertisers or websites. Thus, we are advising
        you to consult the respective Privacy Policies of these third-party ad
        servers for more detailed information. It may include their practices
        and instructions about how to opt-out of certain options.
      </p>
      <p>
        You can choose to disable cookies through your individual browser
        options. To know more detailed information about cookie management with
        specific web browsers, it can be found at the browsers respective
        websites.
      </p>
      <h2 class="title">Retention of Your Personal Data</h2>
       <p>
        The Company will retain Your Personal Data only for as long as is necessary
        for the purposes set out in this Privacy Policy. We will retain and use Your
        Personal Data to the extent necessary to comply with our legal obligations
        (for example, if we are required to retain your data to comply with applicable
        laws), resolve disputes, and enforce our legal agreements and policies.

        The Company will also retain Usage Data for internal analysis purposes. Usage
        Data is generally retained for a shorter period of time, except when this data
        is used to strengthen the security or to improve the functionality of Our
        Service, or We are legally obligated to retain this data for longer time
        periods.
       </p>
      <h2 class="title">Delete Your Personal Data </h2>
      <p>
        You can close your account by visiting your profile profile page on our app. We will remove your public posts
        from view and/or dissociate them from your account profile, but we may retain information about you for the purposes
        authorized under this Privacy Policy unless prohibited by law. Thereafter, we will either delete your personal
        information or de-identify it so that it is anonymous and not attributed to your identity. For example, we may retain
        information to prevent, investigate, or identify possible wrongdoing in connection with the Service or to comply with
        legal obligations.
      </p>
      <h2 class="title">Links to Other Websites</h2>
      <p>Our Service may contain links to other websites that are not operated by Us. If You click on a third party link, You
        will be directed to that third party's site. We strongly advise You to review the Privacy Policy of every site You
        visit.</p>
      <p>We have no control over and assume no responsibility for the content, privacy policies or practices of any third
        party sites or services.</p>

      <h2 class="title">Changes to this Privacy Policy</h2>
      <p>We may update Our Privacy Policy from time to time. We will notify You of any changes by posting the new Privacy Policy on this page.</p>
      <p>We will let You know via email and/or a prominent notice on Our Service, prior to the change becoming effective and update the &quot;Last updated&quot; date at the top of this Privacy Policy.</p>
      <p>You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.</p>

      <h2 class="title">Contact Us</h2>
      <p>If you have any questions about this Privacy Policy, You can contact us:</p>
      <ul>
      <li>By email: general@hometowngalleria.in</li>
      </ul>
    </div>
  </div>
  <!-- <app-footer [isHomePage]="true"></app-footer> -->
</div>
