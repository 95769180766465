<div class="dining-gallery">
    <div class="dining-gallery__items">

        <div class="dining-gallery__item-2">
            <img [src]="galleryItemList.left_image.image" alt="left-img" />
        </div>
        <div class="dining-gallery__item-1">
            <div>
                <img [src]="galleryItemList.right_image1.image" alt="right-img-1" />
            </div>
            <div>
                <img [src]="galleryItemList.right_image2.image" alt="right-img-2" />
            </div>
        </div>
        <div class="dining-gallery__item-1">
            <div>
                <img [src]="galleryItemList.right_image3.image" alt="right-img-3" />
            </div>
            <div class="dining-gallery__last-grid-item ">
                <img [src]="galleryItemList.right_image4.image" alt="right-img-4" />
                <span (click)="openGallery()">
                    +{{galleryItemList.total_count}} Photos
                </span>
            </div>
        </div>
    </div>
</div>