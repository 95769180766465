<!-- 
<div class="photo-grid">
    <div class="photo-grid__items">
        <div class="photo-grid__item-1">
            <img
                src="https://cf.bstatic.com/xdata/images/hotel/max500/335865449.jpg?k=5393edc0d3dcaffaae6d0f8746032cee743f330f1133342faca526fbb3247037&o=&hp=1" />
            <img
                src="https://cf.bstatic.com/xdata/images/hotel/max500/335865449.jpg?k=5393edc0d3dcaffaae6d0f8746032cee743f330f1133342faca526fbb3247037&o=&hp=1" />
        </div>
        <div class="photo-grid__item-2">
            <img
                src="https://cf.bstatic.com/xdata/images/hotel/max1024x768/339155957.jpg?k=e30a8d7293733f54c2c6ec5d226f6e2351f9575b91f67afc827de834eb45b610&o=&hp=1" />
        </div>
    </div>
    <div class="photo-grid__thumbs-wrapper">
        <a>
            <img
                src="https://pix8.agoda.net/hotelImages/437/43775/43775_16040614390041350417.jpg?ca=6&ce=1&s=450x450" />
        </a>
        <a>
            <img
                src="https://pix8.agoda.net/hotelImages/437/43775/43775_16040614390041350417.jpg?ca=6&ce=1&s=450x450" />
        </a>
        <a>
            <img
                src="https://pix8.agoda.net/hotelImages/437/43775/43775_16040614390041350417.jpg?ca=6&ce=1&s=450x450" />
        </a>
        <a>
            <img
                src="https://pix8.agoda.net/hotelImages/437/43775/43775_16040614390041350417.jpg?ca=6&ce=1&s=450x450" />
        </a>
        <a>
            <img
                src="https://cf.bstatic.com/xdata/images/hotel/max300/335865447.jpg?k=085f486b0079b59b7ec9906fe403586932f14c383c551dad64ba009cc6f291e5&o=&hp=1" />
            <span (click)="openGallery()">
                +31 Photos
            </span>
        </a>
    </div>
</div> -->


<div class="photo-grid" *ngIf="galleryItemList">
    <div class="photo-grid__items">
        <div class="photo-grid__item-1">
            <img [src]="galleryItemList?.left_image1?.image" alt="image_1"  *ngIf="galleryItemList?.left_image1?.image"/>
            <img [src]="galleryItemList?.left_image2?.image" alt="image_2" *ngIf="galleryItemList?.left_image2?.image"/>
        </div>
        <div class="photo-grid__item-2" *ngIf="galleryItemList?.right_image?.image">
            <img [src]="galleryItemList?.right_image?.image" alt="image_3"  />
        </div>
    </div>
    <div class="photo-grid__thumbs-wrapper">
        <a *ngFor="let item of galleryItemList?.bottom_image; let i = index;">
            <img [src]="item?.image" [alt]="'bottom_image_'+i" />
            <span (click)="openGallery()" *ngIf="i === galleryItemList.bottom_image.length-1">
                +{{galleryItemList.total_count}} Photos
            </span>
        </a>
    </div>
</div>