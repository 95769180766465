import { Directive, EventEmitter, HostListener, OnInit, Output } from '@angular/core';
import { Subject, debounceTime } from 'rxjs';

@Directive({
  selector: '[appScroll]'
})
export class ScrollDirective implements OnInit {
@Output() scrollToBottom:EventEmitter<boolean> = new EventEmitter<boolean>();
modelChanged: Subject<boolean> = new Subject<boolean>();
  constructor() { }

  ngOnInit(): void {
    this.modelChanged.pipe(debounceTime(1000)).subscribe((model) => {
   
    });
  }

  @HostListener('window:scroll', []) onWindowScroll() {
    if (
      document.documentElement.offsetHeight /2 <
      document.documentElement.scrollTop + 100
    ) {
      this.scrollToBottom.emit(true);
    } else {
      this.scrollToBottom.emit(false);
    }
  }

}
