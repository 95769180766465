
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PrivacyComponent } from './modules/home/components/privacy/privacy.component';
import { TermOfServiceComponent } from './modules/home/components/term-of-service/term-of-service.component';
import { DeleteMyAccountComponent } from './modules/home/components/delete-my-account/delete-my-account.component';


const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full'},
  { path: 'privacypolicy', component: PrivacyComponent},
  { path: 'term-of-service', component: TermOfServiceComponent},
  { path: 'delete-my-account', component: DeleteMyAccountComponent },
  { path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule)
  },
  {
    path: 'authentication',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('./modules/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'booking',
    loadChildren: () => import('./modules/hotelBooking/hotel-booking.module').then(m => m.HotelBookingModule)
  },
  {
    path: 'dining',
    loadChildren: () => import('./modules/dining/dining.module').then(m => m.DiningModule)
  },
  {
    path: 'event',
    loadChildren: () => import('./modules/events/events.module').then(m => m.EventsModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
