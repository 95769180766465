<div class="full-page__wrapper" *ngIf="!isHeader">
    <app-header></app-header>
    <div class="container">
        <div class="gallery-wrapper__cross" (click)="backClicked()">x</div>
        <div class="gallery-wrapper gallery-wrapper__center">
            <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
        </div>
    </div>
    <app-footer></app-footer>
</div>
<div class="container" *ngIf="isHeader">
    <div class="gallery-wrapper">
        <ngx-gallery [options]="galleryOptions" [images]="galleryImages" class="ngx-gallery"></ngx-gallery>
    </div>
</div>