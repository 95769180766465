<div class="privacy">
  <app-header></app-header>
  <div class="container">
    <div class="delete-my-account">
      <h2 class="title">How to request for Account deletion</h2>
      <h2 class="subtitle">Step 1:</h2>
      <p>You can request for acccount deletion from our mobile app</p>

      <p>Android: </p>
      <a href="https://play.google.com/store/apps/details?id=com.zodiak.org.zodiak&hl=en-IN" target="_blank">
        https://play.google.com/store/apps/details?id=com.zodiak.org.zodiak&hl=en-IN
      </a>

      <p class="ios">IOS: </p>
      <a href="https://apps.apple.com/in/app/zt-loyalty/id6462909082" target="_blank">
        https://apps.apple.com/in/app/zt-loyalty/id6462909082
      </a>

      <h2 class="subtitle">Step 2:</h2>
      <p>Login with your credentials</p>

      <h2 class="subtitle">Step 3:</h2>
      <p>Go to Profile section</p>

      <h2 class="subtitle">Step 4:</h2>
      <p>Click on "Delete Account"</p>
    </div>
  </div>
