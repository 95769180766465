import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { ApiService } from 'src/app/services/api.service';
import { CommonService } from 'src/app/services/common.service';
import { ModalComponent } from '../modal/modal.component';
import { ModalService } from 'src/app/services/modal.service';

@Component({
  selector: 'app-address-list',
  templateUrl: './address-list.component.html',
  styleUrls: ['./address-list.component.scss'],
})
export class AddressListComponent implements OnInit {
  @Output() changeAddress:EventEmitter<any> = new EventEmitter<any>();
  @Input() isModifyAddressRequired!: boolean; // If wants to show delete and default option then this veriavble should be true
  @Input() isAddAddressasModal!: boolean; // If add address form comes as modal then true
  showAddAddress: boolean = false;
  @Input() isFoodSummery:boolean = false;
  addressList: any;
  @ViewChild('modalRef')
  private modalComponent!: ModalComponent;

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private modalService: ModalService
  ) {}

  ngOnInit(): void {
    this.getProfileDetails();
    this.modalService.isModalClosed$.subscribe(data => {
      if(data) {
        this.showAddAddress=false;
      }
    })
  }

  getProfileDetails() {
    const self = this;
    this.apiService.getProfileDetails().subscribe({
      next(response: any) {
        if (response.body) {
          const data = response.body;
          const { userDetails, subscription } = data;
          self.addressList = userDetails.address;
          let userData = self.commonService.getUserDetails();
          userData.userDetails = data;
          self.commonService.setUserDetails({
            ...userData
          })
          self.changeAddress.emit(self.addressList[0]);
        }
      },
      error(error) {
        self.commonService.updateAlertData(error);
      },
    });
  }

  openAddressModal() {
    if (this.isAddAddressasModal) {
      this.openModal();
    } else {
      this.showAddAddress = true;
    }
  }

  onAddressUpdated(data: any) {
    if(data) {
      this.getProfileDetails();
    }
    if(!this.isFoodSummery) {
      this.modalService.setModalData(false);
    } else {
      this.showAddAddress = false;
    }
  }

  deleteAddress(address: any) {
    const self = this;
    this.apiService.deleteAddress(address._id).subscribe({
      next(response: any) {
        if (response.status === 'Success') {
          const index = self.addressList.findIndex(
            (element: any) => element._id === address._id
          );
          if (index > -1) {
            self.addressList.splice(index, 1);
          }
        }
      },
      error: this.handleErrorResponse.bind(this),
    });
  }

  makeAddressDefault(address: any) {
    const self = this;
    this.apiService.defaultAddress(address._id).subscribe({
      next(response: any) {
        if (response.status === 'Success') {
          self.getProfileDetails();
        }
      },
      error: this.handleErrorResponse.bind(this),
    });
  }

  handleErrorResponse(response: any) {
    this.commonService.updateAlertData(response);
  }

  async openModal() {
    await this.modalComponent.open();
  }
  async selectedAddress(data:any) {
    this.changeAddress.emit(data);
    await this.modalComponent.hide();
  }
}
