import { Subject, debounceTime } from 'rxjs';
import { CommonService } from './../../../../services/common.service';
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';

@Component({
  selector: 'app-dining-header',
  templateUrl: './dining-header.component.html',
  styleUrls: ['./dining-header.component.scss'],
})
export class DiningHeaderComponent implements OnInit {
  @Input() isSearch: boolean = false;
  @Input() isButton: boolean = false;
  @Input() cartTotalCount!: number;
  @Input() headerData!: any;
  @Output() buttonClicked = new EventEmitter();
  @Output() searched = new EventEmitter();
  isDesktop!: boolean;
  searchedText!: string;
  modelChanged: Subject<string> = new Subject<string>();
  @Output() changedSearchedText: EventEmitter<string> =
    new EventEmitter<string>();
  @Output() goToCheckout:EventEmitter<string> = new EventEmitter<string>();
  @Output() changedFilter = new EventEmitter();

  constructor(private commonService: CommonService) {}

  ngOnInit(): void {
    this.isDesktop = this.commonService.isDesktop();
    this.modelChanged.pipe(debounceTime(1000)).subscribe((model) => {
      this.searchedText = model;
      // if (this.searchedText.length > 2) {
        this.changedSearchedText.emit(this.searchedText);
      // }
    });
  }
  updateSearchedText() {
    this.modelChanged.next(this.searchedText);
  }

  debounce(callback: any, delay = 1000) {
    var time: any;
    return (...args: any[]) => {
      clearTimeout(time);
      time = setTimeout(() => {
        callback(...args);
      }, delay);
    };
  }

  clearSearchedText() {
    this.searchedText = '';
    this.changedSearchedText.emit(this.searchedText);
  }

  goToSummery() {
    this.goToCheckout.emit('');
  }
  
}
