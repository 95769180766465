<ng-template #modalRef let-c="close" let-d="dismiss" class="modal">
	<div class="modal-header">
		<h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="d('Cross click');closeModal()"></button>
	</div>
	<div class="modal-body">
		<ng-content></ng-content>
	</div>
	<!-- <div class="modal-footer">
		<button type="button" class="modal__cta" (click)="c('Save click')">Save</button>
	</div> -->
</ng-template>

