import { Component } from '@angular/core';

@Component({
  selector: 'app-term-of-service',
  templateUrl: './term-of-service.component.html',
  styleUrls: ['./term-of-service.component.scss']
})
export class TermOfServiceComponent {

}
