import { CommonService } from 'src/app/services/common.service';
import { ApiService } from 'src/app/services/api.service';
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthenticationUtlis } from 'src/app/modules/authentication/utils/authentication.utils';
import { Subject, debounceTime } from 'rxjs';

@Component({
  selector: 'app-address',
  templateUrl: './address.component.html',
  styleUrls: ['./address.component.scss'],
})
export class AddressComponent implements OnInit {
  @Output() addressUpdated = new EventEmitter();
  addressForm!: FormGroup;
  locationList!: any;
  locationData= {
    location: '',
    lat: '',
    long: ''
  };
  selectLocation!: any;
  modelChanged: Subject<string> = new Subject<string>();

  @ViewChild('addDrop') addDrop!: any;

  constructor(
    private apiService: ApiService,
    private commonService: CommonService,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.addressForm = new FormGroup({
      fName: new FormControl(null, Validators.required),
      lName: new FormControl(null),
      address: new FormControl(null, Validators.required),
      city: new FormControl(null, Validators.required),
      state: new FormControl(null, Validators.required),
      country: new FormControl(null, Validators.required),
      pin: new FormControl(null, Validators.required),
    });

    this.modelChanged.pipe(debounceTime(1000)).subscribe((model) => {
      this.selectLocation = model;
      if (this.selectLocation.length > 2) {
        this.getAutocompleteLocation(this.selectLocation);
      }
      this.cdRef.detectChanges();
    });
  }
  saveAddress(address: any) {
    const self = this;
    const payload = {
      address: [
        {
          title: address.fName + ' - ' + address.lName,
          address: {
            country: address.country,
            city: address.city,
            state: address.state,
            zip: address.pin,
            street_address: address.address,
            lat: this.locationData.lat,
            long: this.locationData.long,
            location: this.locationData.location,
          },
        },
      ],
    };
    this.apiService.addAddress(payload).subscribe({
      next(response: any) {
        if (response.status === 'success') {
          self.addressForm.reset();
          self.addressUpdated.emit(true);
        } else {
          self.addressUpdated.emit(false);
        }
      },
      error(error) {
        self.commonService.updateAlertData(error);
        self.addressUpdated.emit(false);
      },
    });
  }

  getAutocompleteLocation(searchedTest: any) {
    const self = this;
    this.apiService.getAutocompleteLocation(searchedTest).subscribe({
      next(response: any) {
        const data = response.body;
        self.locationList = data;
        self.openDropdown();
        self.cdRef.detectChanges();
      },
      error(error) {
        self.addressUpdated.emit(false);
      },
    });
  }
  getSelectedLocationDetails(placeId: any) {
    const self = this;
    this.apiService.getLocationDetails(placeId).subscribe({
      next(response: any) {
        const { city, country, lat, location, long, postal_code, state } =
          response.body;
          const {fName,lName,address, pin} = self.addressForm.value;
        self.addressForm.patchValue({
          fName: fName || '',
          lName: lName || '',
          address: address || '',
          city: city || '',
          state: state || '',
          country: country || '',
          pin: postal_code || pin || '',
        });
        self.locationData = { lat, location, long };
        self.cdRef.detectChanges();
      },
      error(error) {
        self.addressUpdated.emit(false);
      },
    });
  }
  validateNo(e: any): boolean {
    return AuthenticationUtlis.isNumber(e);
  }
  selectLocationChange(data: any) {
    this.selectLocation = data.description;
    this.getSelectedLocationDetails(data.place_id);
  }
  changeLocation() {
    this.selectLocation = '';
    this.addressForm.patchValue({
      fName: '',
      lName: '',
      address: '',
      city: '',
      state: '',
      country: '',
      pin: '',
    });
    this.locationData = { lat: '', location: '', long: '' };
    this.cdRef.detectChanges();
  }
  openDropdown() {
    this.addDrop.open();
  }
}
