import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModalService {
  openModal$ = new BehaviorSubject(false);
  isModalClosed$ = new BehaviorSubject(false);
  constructor() { }

  setModalData(data: boolean) {
    this.openModal$.next(data);
  }
  setModalClosedData(data: boolean) {
    this.isModalClosed$.next(data);
  }
}
