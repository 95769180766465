import { CommonService } from 'src/app/services/common.service';
import { Component, OnInit, Input } from '@angular/core';
import { NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions } from '@kolkov/ngx-gallery';
import {Location} from '@angular/common';

@Component({
  selector: 'app-photo-gallery-details',
  templateUrl: './photo-gallery-details.component.html',
  styleUrls: ['./photo-gallery-details.component.scss']
})
export class PhotoGalleryDetailsComponent implements OnInit {
  @Input() isHeader = false;
  isDesktop!:boolean;
  galleryHeight!:string;
  galleryOptions!: NgxGalleryOptions[];
  galleryImages: NgxGalleryImage[] = [];
  galleryWidth!:string;
  @Input() galaryList!:any;
  constructor(private commonService: CommonService, private _location: Location) { }
  ngOnInit(): void {
    this.galleryWidth = '588px';
    if(!this.isHeader) {
      this.galleryWidth = '850px';
      this.galleryHeight = '600px';
    }

    this.galleryOptions = [
      {
        width: this.galleryWidth,
        height: this.galleryHeight,
        thumbnailsColumns: 4,
        arrowPrevIcon: 'fa fa-chevron-left',
        arrowNextIcon: 'fa fa-chevron-right',
        imageAnimation: NgxGalleryAnimation.Slide
      },
      // max-width 800
      {
        breakpoint: 800,
        width: '100%',
        height: this.galleryHeight,
        imagePercent: 80,
        thumbnailsPercent: 20,
        thumbnailsMargin: 20,
        thumbnailMargin: 20
      },
      // max-width 400
      {
        breakpoint: 400,
        preview: false
      }
    ];
    if(this.galaryList) {
      this.galleryImages = this.galaryList;
    } else {
      const { updatedGalleryListForDetails } =  this.commonService.getGalleryDataForGalleryDetails();
      this.galleryImages = updatedGalleryListForDetails;
    }

  }

  backClicked() {
    this._location.back();
  }

}
