import { Component, Input } from '@angular/core';
import { CONSTANTS } from '../../constans';
import { CommonService } from 'src/app/services/common.service';

@Component({
  selector: 'app-no-data',
  templateUrl: './no-data.component.html',
  styleUrls: ['./no-data.component.scss']
})
export class NoDataComponent {
@Input() data:string = CONSTANTS.noDataError;

constructor(private commonService:CommonService){
  commonService.setLoader(false);
}
}
