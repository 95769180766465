import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
@Input() isHomePage: boolean = false;
@Input() isFooterFixedToBottom:boolean = false;
footerItem = [
  // {
  //   name: 'About Zodiak.com',
  //   path:''
  // },
  // {
  //   name: 'Customer Service help',
  //   path:''
  // },
  {
    name: 'Term Of Service',
    path:'term-of-service'
  },
  {
    name: 'Privacy & Policy',
    path:'privacypolicy'
  }
]
  constructor(  private routes: Router) { }

  ngOnInit(): void {
  }
  goToPage(path:any) {
    this.routes.navigate([path]);
  }

}
