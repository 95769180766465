<div class="address">
    <div class="address__block">
        <div class="address__autoaddress">
            <input type="text" placeholder="  Select location" [(ngModel)]="selectLocation" id="dropdownManual1"
                autocomplete="off" (ngModelChange)="modelChanged.next($event)">
            <i class="fa-sharp fa-solid fa-location-dot"></i>
            <a class="address__autoaddress--change" *ngIf="selectLocation" (click)="changeLocation()">Change</a>
            <div ngbDropdown #addDrop="ngbDropdown" class="address__autoaddress--item">
                <div ngbDropdownMenu aria-labelledby="dropdownManual1">
                    <p ngbDropdownItem *ngFor="let location of locationList;" (click)="selectLocationChange(location)">
                        {{location?.description}}</p>
                </div>
            </div>
            <p class="error" *ngIf="!(locationList&&locationList.length>0)">Please select location</p>
        </div>
        <form [formGroup]="addressForm" (ngSubmit)="saveAddress(addressForm.value)">
            <div class="floating-form">
                <div class="address__name-wrapper">
                    <div class="floating-label">
                        <input class="floating-input" type="text" placeholder=" " formControlName="fName"
                            maxlength="15" />
                        <span class="highlight"></span>
                        <label>First Name</label>
                    </div>
                    <div class="floating-label">
                        <input class="floating-input" type="text" placeholder=" " formControlName="lName"
                            maxlength="15" />
                        <span class="highlight"></span>
                        <label>Last Name</label>
                    </div>
                </div>
                <div class="floating-label">
                    <input class="floating-input" type="text" placeholder=" " formControlName="address" />
                    <span class="highlight"></span>
                    <label>Address </label>
                </div>
                <div class="floating-label" *ngIf="locationData.location">
                    <p>Location</p>
                    {{locationData.location}}
                </div>
                <div class="address__name-wrapper">
                    <div class="floating-label">
                        <input class="floating-input" type="text" placeholder=" " formControlName="city"
                            maxlength="15" />
                        <span class="highlight"></span>
                        <label>City </label>
                    </div>
                    <div class="floating-label">
                        <input class="floating-input" type="text" placeholder=" " formControlName="state"
                            maxlength="15" />
                        <span class="highlight"></span>
                        <label>State </label>
                    </div>
                </div>
                <div class="address__name-wrapper">
                    <div class="floating-label">
                        <input class="floating-input" type="tel" placeholder=" " formControlName="pin" minlength="6"
                            maxlength="6" (keypress)="validateNo($event)" />
                        <span class="highlight"></span>
                        <label>pin </label>
                    </div>
                    <div class="floating-label">
                        <input class="floating-input" type="text" placeholder=" " formControlName="country"
                            maxlength="15" />
                        <span class="highlight"></span>
                        <label>Country </label>
                    </div>
                </div>
                <button [disabled]="!addressForm.valid || !locationData.location || !locationData.lat || !locationData.long" class="app-secondry-btn">Submit</button>
            </div>
        </form>
    </div>
</div>