import { ModalService } from './../../../../services/modal.service';
import { Component, ElementRef, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter, NgZone } from '@angular/core';
import { NgbModal, NgbModalConfig } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
  providers: [NgbModalConfig, NgbModal],
})
export class ModalComponent implements OnInit {
  @ViewChild('modalRef', { static: true }) modalRef!: ElementRef;
  @Input('title') title!: string;

  constructor(config: NgbModalConfig, private modalService: NgbModal, private modalCustomService: ModalService, private ngZone: NgZone,) {
		// customize default values of modals used by this component tree
		config.backdrop = 'static';
		config.keyboard = false;
	}

	open(customClass:any='') {
    this.ngZone.run(()=> {
      this.modalService.open(this.modalRef,  { windowClass: customClass});
    })
	}

  hide() {
    this.modalService.dismissAll();
  }

  ngOnInit(): void {
    this.modalCustomService.openModal$.subscribe(data => {
      data && this.open();
      !data && this.hide();
    })
  }

  closeModal() {
    this.modalCustomService.setModalClosedData(true);
  }

}
