<div class="dining-details">
    <div class="full-page__wrapper">
        <app-header></app-header>
        <div class="container">
            <div class="dining-details__header" *ngIf="isDesktop">
                <app-dining-header [isSearch]="false" [isButton]="true" (buttonClicked)="goTO()"
                    [headerData]="restaurantHeaderData" *ngIf="restaurantHeaderData"></app-dining-header>
            </div>
            <div *ngIf="isDesktop">
                <div class="dining-details__web">
                    <div class="dining-details__grid">
                        <app-dining-gallery [galleryItemList]="restaurantDetails?.gallery_hero"
                            *ngIf="restaurantDetails?.gallery_hero"></app-dining-gallery>
                    </div>
                </div>
            </div>
            <div class="dining-details__wrapper" *ngIf="!isDesktop">
                <app-banner [carouselList]="galleryList.updatedGalleryListForBanner"></app-banner>
                <app-dining-header [isSearch]="false" [isButton]="true" (buttonClicked)="goTO()"
                    [headerData]="restaurantHeaderData" *ngIf="restaurantHeaderData"></app-dining-header>
            </div>
            <div class="dining-details__summery">
                <div class="dining-details__details card">
                    <div>
                        <p class="dining-details__menu-text">Menu</p>
                        <div class="dining-details__item-list">
                            <div class="dining-details__item" (click)="goToLink(menu.menu_link)"
                                *ngFor="let menu of restaurantDetails?.menu">
                                <img [src]="menu.image" [alt]="menu.name">
                                <p>{{menu.name}}</p>
                            </div>
                        </div>
                    </div>
                    <div class="dining-details__price-info">
                        <span class="dining-details__title">Average cost</span>
                        <span class="dining-details__sub-info">{{restaurantDetails?.price_details}}</span>
                        <span class="dining-details__info">Exclusive of applicable tax and charges. if any</span>
                        <span class="dining-details__sub-info"
                            *ngFor="let info of restaurantDetails?.other_info">{{info.info}}</span>
                    </div>
                    <div class="dining-details__price-info">
                        <span class="dining-details__title"> More Info</span>
                        <app-facilities [facilitiesList]="restaurantDetails.facilities"
                            *ngIf="restaurantDetails"></app-facilities>
                    </div>
                </div>
                <div class="dining-details__contact card">
                    <span class="app-header-style">Call For Reservations and Enquires</span>
                    <a class="dining-details__contact-number"
                        [href]="'tel:' + restaurantDetails?.settings.contact_1">{{restaurantDetails?.settings.contact_1}}</a>
                    <a class="dining-details__contact-number"
                        [href]="'tel:' + restaurantDetails?.settings.contact_2">{{restaurantDetails?.settings.contact_2}}</a>
                    <div class="dining-details__map"  [innerHtml]="restaurantDetails?.map | safeHtml">
                        <!-- <div [innerHtml]="restaurantDetails?.map | safeHtml">
                        </div> -->
                    </div>
                    <span class="dining-details__address" *ngIf="restaurantAddress">{{restaurantAddress}}</span>
                    <!-- <div class="dining-details__btn">
                        <button class="app-secondry-btn">Direction</button>
                    </div> -->
                </div>
            </div>
        </div>
        <app-footer></app-footer>
    </div>
</div>