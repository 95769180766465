import { CommonService } from './../../../../services/common.service';
import { Component, Input, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CONSTANTS } from '../../constans';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  isNavigationRequired!: boolean;
  isLoggedIn!: boolean;
  authList: any = [];
  headerCategory: any = [];
  alertData:any;
  userDetails:any;
  showNotification!:boolean;
  orderListForFeedback!:any;
  brandName = CONSTANTS.brandName;
  @Input() isNotificationRequired!:boolean;
  isAlertOpen!:boolean;
  constructor(
    private router: Router,
    public commonService: CommonService,
    private ngZone: NgZone,
    private apiService: ApiService
  ) {}

  ngOnInit() {
    this.authList = this.commonService.authList;
    this.headerCategory = this.commonService.headerCategory;
    this.userDetails = this.commonService.getUserDetails()
    this.commonService.isHeaderNavigationRequired$.subscribe((data) => {
      this.isNavigationRequired = data;
    });
    this.commonService.isLoggedInUser$.subscribe((data) => {
      this.isLoggedIn = data;
      if(this.isLoggedIn && this.isNotificationRequired) {
        this.getOrderListForFeedBack();
      }
    });
    this.isLoggedIn = this.commonService.isUserAuthenticated();
    // this.commonService.alertDataUpdated$.subscribe((data)=> {
    //   this.alertData = data;
    //   if(this.alertData) {
    //     this.isAlertOpen = true;
    //   } else {
    //     this.isAlertOpen = false;
    //   }
    // })
    this.commonService.isUserDataUpdated$.subscribe((data)=> {
      // this.alertData = data;
      if(data) {
        this.getProfileDetails()
      }
    })
    if(this.isLoggedIn&& this.isNotificationRequired) {
      this.getOrderListForFeedBack();
    }
  }

  getOrderListForFeedBack() {
    const self = this;
    this.apiService.getOrderListForFeedBack().subscribe({
      next(response: any) {
        if (response.body) {
          const {orders} = response.body;
          self.orderListForFeedback = orders;
        }
      },
      error(error) {
        self.commonService.updateAlertData(error);
      },
    });
  }
  getProfileDetails() {
    const self = this;
    this.apiService.getProfileDetails().subscribe({
      next(response: any) {
        if (response.body) {
          const data = response.body;
          self.userDetails = {
            userDetails : {
              subscription: data.subscription
            },
            name:data.userDetails.name
          };
          const userData = self.commonService.getUserDetails();
          self.commonService.setUserDetails({ ...userData, userDetails:data });
        }
      },
      error(error) {
        self.commonService.updateAlertData(error);
      },
    });
  }

  gotToAuth(auth: any) {
    this.commonService.authList.forEach((ele) => (ele.isActive = false));
    auth.isActive = true;
    this.goTo(auth.path);
  }

  goTo(page: string) {
    this.router.navigate([page]);
  }

  goToCatogory(category: any) {
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.commonService.headerCategory.forEach(
          (element) => (element.isActive = false)
        );
        category.isActive = true;
        this.goTo(category.path);
      });
    });
  }
  getSmallName(name: string) {
    const nameArray = name.split(' ');
    return nameArray.length >1 ?  (nameArray[0].charAt(0) + nameArray[1].charAt(0)).toUpperCase() : nameArray[0].charAt(0);
  }
  goToHome() {
        this.commonService.headerCategory.forEach(
          (element) => (element.isActive = false)
        );
        this.goTo('/');
  }
  goToFeedback(oderData:any) {
    this.commonService.setFeedbackData(oderData)
    this.goTo('/authentication/feedback-form')
  }
}
