import { CommonService } from 'src/app/services/common.service';
import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {  Router } from '@angular/router';
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

interface Alert {
	type: string;
	message: string;
}

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit{
	// @Input() data!:any;
	// alertData: any = {
	// 	type: 'success',
	// 	message: 'This is an success alert'
	// };
	alertData!:any;
	isAlertOpen!:boolean;
  constructor(private routers: Router, private commonService:CommonService,private cdRef: ChangeDetectorRef) {
		this.reset();
	} 
	ngOnInit(): void {
		// if(this.data) {
		// 	this.alertData = {
		// 		type: this.data.type ? this.data.type : 'success',
		// 		message: this.data.message ? this.data.message : ' This is an success alert'
		// 	}
		// }
		// if(this.data&&this.data.message === 'Token Expired') {
		// 	this.commonService.clearLoaclAndSessionData();
		// 	this.routers.navigate(['authentication/login'])
		// }
		this.commonService.alertDataUpdated$.subscribe((data)=> {
			this.alertData = data;
			if(this.alertData&&this.alertData.message) {
			  this.isAlertOpen = true;
			} else {
			  this.isAlertOpen = false;
			}
			if(typeof this.alertData.message !== 'string') {
				this.alertData.message = 'Please try again.'
			}
			// this.cdRef.detectChanges(); 
			if(this.alertData&&this.alertData.message === 'Token Expired') {
				this.commonService.clearLoaclAndSessionData();
				this.routers.navigate(['authentication/login'])
			}
		  })
	}
  close() {
		this.commonService.updateAlertData({})
	}

	reset() {
		// this.alerts = Array.from(ALERTS);
	}
}
