import { AbstractControl, FormControl } from "@angular/forms";

export class AuthenticationUtlis {

    static isNumber(e: any) {
        const charCode = e.which ? e.which : e.keyCode;
        if (charCode > 31 && (charCode < 48 || charCode > 57)) {
          return false
        }
        return true
    }

    static emailValidator(control: any) {
        if (
          control.value &&
          control.value.match(
            // tslint:disable-next-line:max-line-length
            /(^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$)/
          )
        ) {
          return null;
        } else if (!control.value) {
          return null;
        } else {
          return { invalidEmail: true };
        }
      }
      
      static phoneNumberValidator(control: any) {
        if (control.value != null && String(control.value).match(/^[0-9]{10}$/)) {
          return null;
        } else if (control.value === 0 || control.value == null) {
          return null;
        } else {
          return { invalidMobile: true };
        }
      }
      static isPasswordValid(controls: FormControl) {
        if (controls.value && controls.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{10,}$/)) {
          return null;
        } else if (!controls.value) {
          return null;
        } else {
          return { inValidPassword: true }
        }
      }
      /**
       * This metho will check wether password and confirm password is equal or not
       * @param controls is the confirm Password element
       * @returns passwordConfirmingType or null
       */
      static passwordConfirming(controls: AbstractControl){
    
        return controls.root.get('password')?.value !== controls?.value ? { invalidPasswordMatch: true } : null
      }
      /**
       * This method will check wether current password is not same as new password
       * @param controls is the password element
       * @returns null | currentAndNewPasswordValidationType
       */
      static currentAndNewPasswordValidation(controls: AbstractControl) {
    
        return controls.root.get('currentPassword')?.value === controls?.value ? { invalidResetPassword: true } : null
    
      }
}