import { Router } from '@angular/router';
import { Component, OnInit, Input, ElementRef } from '@angular/core';

@Component({
  selector: 'app-photo-grid',
  templateUrl: './photo-grid.component.html',
  styleUrls: ['./photo-grid.component.scss']
})
export class PhotoGridComponent implements OnInit {
  @Input() galleryItemList!: any;
  constructor( private route: Router, private elem: ElementRef) { 
    this.elem.nativeElement.style.setProperty('$grid-height', 400);
  }

  ngOnInit(): void {
  }
  openGallery() {
    this.route.navigate(['booking/gallery']);
  }
}
